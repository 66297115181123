export const SET_TRANSLATION = 'set_translation';

export const SET_USER_TOKEN = 'set_user_token';
export const SET_USER = 'set_user';

export const RESET_UPLOAD_FILES = 'reset_upload_files';
export const ADD_UPLOAD_FILES = 'add_upload_files';
export const REMOVE_FILE_FROM_UPLOAD = 'remove_file_from_upload';
export const SET_TOTAL_UPLOAD_PROGRESS = 'set_total_upload_progress';
export const SET_FILE_LIST = 'set_file_list';
export const UPSERT_FILE_LIST_ITEM = 'upsert_file_list_item';

export const SET_ROOM_SOCKET = 'set_room_socket';
export const SET_ROOM_INFO = 'set_room_info';
export const SET_ROOM_LIST = 'set_room_list';

export type User = {};
