import Layout from '../Layout/Layout';
import './Photovoltaic.scss';
import Intro from './Intro/Intro';
import Overview from './Overview/Overview';
import { useSelector } from 'react-redux';
import Goals from './Goals/Goals';
import Numberation from './Numberation/Numberation';
// import PresentationBanner from './PresentationBanner/PresentationBanner';
// import Presentation from './Presentation/Presentation';
import Advantages from './Advantages/Advantages';
import Construction from './Construction/Construction';
import Requirements from './Requirements/Requirements';

const Photovoltaic = () => {
  const translation = useSelector(s => s.translation);
  return (
    <div id="photovoltaic">
      <Layout menuSelect={'photovoltaic'}>
        <Intro />
        <Overview />
        <div className="separator">{translation['photovoltaic.separator.text']}</div>
        <Goals />
        <Numberation />
        {/* <PresentationBanner /> */}
        {/* <Presentation /> */}
        {/* readd corners in Numberations.scss */}
        {/* readd padding-top in Advantages.scss */}
        <Advantages />
        <Construction />
        <Requirements />
        <div className="line one"></div>
        <div className="line two"></div>
        <div className="line three"></div>
      </Layout>
    </div>
  );
};

export default Photovoltaic;
