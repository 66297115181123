import { useState } from 'react';
import { getUUID } from '../../../../utility';
import { StyledAccordion, Flex } from './Accordion.Styled.js';
import Item from './Item/Item';

const Accordion = ({ items, className, initOpenIndex = null }) => {
  const [openIndex, setOpenIndex] = useState(initOpenIndex);

  const toggle = index => () => setOpenIndex(openIndex === index ? null : index);

  if (!Array.isArray(items)) return <></>;
  return (
    <StyledAccordion className={className}>
      <Flex>
        {items.map(({ title, content }, i) => (
          <Item
            open={openIndex === i}
            toggle={toggle(i)}
            title={title}
            content={content}
            key={getUUID()}
            id={i}
          />
        ))}
      </Flex>
    </StyledAccordion>
  );
};

export default Accordion;
