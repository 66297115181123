import { FunctionComponent } from 'react';

import './Main.scss';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

// import andreasNeumannImage from '../../../assets/image/about-us/andreas_bild.png';
// import joergReentsImage from "../../../assets/image/about-us/joerg.png";
// import hergenEilersImage from "../../../assets/image/about-us/hergen_bild.png";
// import janHolstImage from '../../../assets/image/about-us/jan_bild.png';

const Main: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="aboutus-main">
      <Container>
        {/* <div className="headline">{translation["aboutus.main.headline"]}</div>
        <div className="text-split">
          <div className="column">
            <div className="text">
              <div className="headline">
                {translation["aboutus.main.text.1.headline"]}
              </div>
              <div className="content">
                {translation["aboutus.main.text.1.text"]}
              </div>
              <div className="cta-wrapper">
                <div className="cta">
                  {translation["aboutus.main.text.cta"]}
                </div>
              </div>
            </div>
            <div className="text">
              <div className="headline">
                {translation["aboutus.main.text.2.headline"]}
              </div>
              <div className="content">
                {translation["aboutus.main.text.2.text"]}
              </div>
              <div className="cta-wrapper">
                <div className="cta">
                  {translation["aboutus.main.text.cta"]}
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="text">
              <div className="headline">
                {translation["aboutus.main.text.3.headline"]}
              </div>
              <div className="content">
                {translation["aboutus.main.text.3.text"]}
              </div>
              <div className="cta-wrapper">
                <div className="cta">
                  {translation["aboutus.main.text.cta"]}
                </div>
              </div>
            </div>
            <div className="text">
              <div className="headline">
                {translation["aboutus.main.text.4.headline"]}
              </div>
              <div className="content">
                {translation["aboutus.main.text.4.text"]}
              </div>
              <div className="cta-wrapper">
                <div className="cta">
                  {translation["aboutus.main.text.cta"]}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="headline">{translation['aboutus.main.person.headline']}</div>
        <div className="person-list">
          <div className="person">
            <div className="image-content-split">
              {/* <div className="image">
                <img src={andreasNeumannImage} alt="Andreas Neumann" />
              </div> */}
              <div className="content">
                <p className="name">{translation['aboutus.main.person.1.name']}</p>
                <p>{translation['aboutus.main.person.1.paragraph.1']}</p>
              </div>
            </div>
          </div>
          <div className="person">
            <div className="image-content-split">
              {/* <div className="image">
                <img src={janHolstImage} alt="janHolst" />
              </div> */}
              <div className="content">
                <p className="name">{translation['aboutus.main.person.2.name']}</p>
                <p>{translation['aboutus.main.person.2.paragraph.1']}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Main;
