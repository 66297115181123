import './DataProtection.scss';

import Container from 'react-bootstrap/Container';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';

import { FunctionComponent } from 'react';

const DataProtection: FunctionComponent = () => {
  return (
    <div id="dataProtection">
      <Layout>
        <Intro />
        <div className="text">
          <Container>
            <p>
              <strong>Datenschutzerklärung</strong>
            </p>
            <p>
              Mit nachfolgenden Informationen möchten wir Ihnen einen Überblick verschaffen, wie wir
              mit Ihren Daten umgehen, die wir im Zusammenhang mit der Nutzung unserer Leistungen
              bei Ihnen erheben, und die wir beim Besuch auf unserer Webseite erfassen. Zudem
              möchten wir Sie über Ihre Datenschutzrechte informieren. Welche Daten im Einzelnen
              verarbeitet, und wie sie genutzt werden, hängt von den jeweils in Anspruch genommenen
              Leistungen ab.
            </p>
            <p>
              <strong>1. Verantwortlicher, Art. 4 Ziffer 7 DSGVO</strong>
            </p>
            <p>
              Der für die Verarbeitung Ihrer Daten Verantwortliche im Sinne von Art. 4 Ziffer 7
              Datenschutz – Grundverordnung (DSGVO) ist:
            </p>
            <p>
              <strong>Jade Concept Kapitalanlageberatungsgesellschaft mbH</strong>
              <br />
              Friedrich-Ebert-Straße 28
              <br />
              26316 Varel
            </p>
            <p>Weitere Informationen finden Sie im Impressum auf unserer Webseite.</p>
            <p>
              <strong>2. Was sind „Personenbezogene Daten"?</strong>
            </p>
            <p>
              Personenbezogene Daten sind Informationen zu Ihrer Person, die Rückschlüsse auf Ihre
              Identität zulassen, z.B. Ihr Name, Ihre Adresse oder Ihre Telefonnummer.
              Informationen, die keine Rückschlüsse auf eine bestimmte oder bestimmbare Person
              zulassen, fallen nicht darunter.
            </p>
            <p>
              <strong>3. Umfang der Datenerhebung, -verarbeitung und -nutzung</strong>
            </p>
            <p>
              <strong>a) Auf unserer Webseite – www.jade-concept.de</strong>
            </p>
            <p>
              aa) Beim rein informatorischen Besuch unserer Webseite erheben wir nur die Daten, die
              Ihr Browser an unseren Server übermittelt. Wenn Sie sich auf unserer Webseite
              informieren möchten, können wir die folgenden Daten erheben, die für uns technisch
              erforderlich sind, um Ihnen unsere Webseite anzuzeigen und die Stabilität und
              Sicherheit zu gewährleisten:
            </p>
            <p>
              <ul>
                <li>IP-Adresse</li>
                <li>Browsertyp/ Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
              </ul>
            </p>
            <p>
              <strong>bb) Kontaktaufnahme</strong>
            </p>
            <p>
              Sie können über unser Anfrageformular oder per E-Mail mit uns Kontakt aufnehmen. Wir
              verarbeiten die von Ihnen mitgeteilten Daten, um Ihre Anfrage zu beantworten.
            </p>
            <p>
              <strong>cc) Cookies</strong>
            </p>
            <p>
              Auf unseren Webseiten werden so genannte Cookies eingesetzt. Diese dienen dazu, unser
              Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
              Textdateien, die auf Ihrem Rechner abgelegt werden, und die Ihr Browser speichert.
              Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
            </p>
            <p>
              Wenn Sie unsere Webseite zum ersten Mal besuchen, werden Sie auf den Einsatz von
              Cookies durch ein sog. Cookie-Banner hingewiesen, welches sich am unteren Rand der
              Webseite öffnet. Standardmäßig aktiviert sind essentielle Cookies, die für den
              sicheren Betrieb der Webseite erforderlich sind. Diese essentiellen Cookies und
              weitere Informationen können Sie sich im „Infocenter“ anzeigen lassen. Sie können
              zusätzliche Cookies freischalten, um die Funktionalität unserer Webseite und die
              Services für Sie weiter zu verbessern. Hierfür müssen Sie im Cookie-Banner die
              angezeigten Cookies z.B. für Statistik und Marketing anschalten. Sie erteilen damit
              Ihre Einwilligung, dass die aktivierten Cookies personenbezogene Daten erfassen und
              wir diese verarbeiten dürfen. Informationen zu Umfang und Zweck der Verarbeitung
              finden Sie ebenfalls im „Infocenter“. Sie können Ihre Einwilligung zur Verarbeitung
              durch die von Ihnen aktivierten Cookies jederzeit widerrufen, indem Sie das Cookie
              Banner erneut aufrufen und die Cookies dort ausschalten. Es werden dann über die
              ausgeschalteten Cookies keine Daten mehr erfasst. Die Rechtmäßigkeit der
              Datenverarbeitung bis zur Abschaltung bleibt hiervon unberührt. Die Einstellung der
              Cookies müssen Sie speichern, so dass diese Einstellung auch bei einem erneuten Besuch
              der Webseite erhalten bleibt und nicht erneut vorgenommen werden muss. Die Speicherung
              der Einstellung erfolgt durch ein Cookie der Fa. Usercentrics, welches als
              essenzielles Cookie auf unserer Webseite freigeschaltet ist und sich Ihre
              Cookie-Einstellung „merkt“. Sie können die Cookie - Einstellung u.a. dadurch
              „löschen“, indem Sie die Seite neu laden. Wir weisen Sie darauf hin, dass Sie bei
              Nichtaktivieren bestimmter Cookies eventuell nicht alle Funktionen unserer Webseite
              nutzen können.
            </p>
            <p>
              <strong>dd) Google Analytics</strong>
            </p>
            <p>
              Im Cookie-Banner können Sie das Analyse-Tool Google Analytics freischalten. Google
              Analytics ist ein Webanalysedienst der Google Ireland LImited. (Google Building Gordon
              House, 4 Barrow St, Dublin, D04 E5W5, Ireland). Daten können unter Umständen an einen
              Server in den USA oder in anderen Drittländern übertragen werden. Weitere
              Informationen zum Umfang der Datenerhebung und zur Verwendung erhobener Daten beim
              Einsatz von Google Analytics finden Sie hier:{' '}
              <a href="https://policies.google.com/privacy?hl=de" rel="noreferrer" target="_blank">
                https://policies.google.com/privacy?hl=de
              </a>
              . Mit der Freigabe von Google Analytics im Cookie – Banner erteilen Sie Ihre
              Einwilligung zur Datenverarbeitung durch Google in unserem Auftrag. Diese Einwilligung
              können Sie jederzeit mit der Wirkung für die Zukunft widerrufen, indem Sie Google
              Analytics im Cookie – Banner wieder deaktivieren.
            </p>
            <p>
              <strong>dd) Google Maps</strong>
            </p>
            <p>
              Um die Standorte unserer Projekte einfacher zu finden, stellen wir auf unserer
              Webseite eine Karte von Google Maps bereit. Google Maps ist ein Service der Google
              LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (Google). Bei dessen
              Nutzung können ggf. personenbezogene Daten durch Google erhoben und ggf. an Google –
              Server in den USA weitergeleitet werden. Weitere Hinweise zum Umfang der
              Datenverarbeitung durch Google finden Sie HIER.
            </p>
            <p>
              <strong>b) Sonstige Verarbeitung von Daten</strong>
            </p>
            <p>
              Wir erheben und speichern personenbezogene Daten, wenn Sie z.B. per Post Anfragen an
              uns richten, Angebote einfordern, Bestellungen abgeben sowie im Rahmen der Begründung
              und Abwicklung von Vertragsverhältnissen.
            </p>
            <p>
              <strong>4. Zweck der Verarbeitung und Rechtsgrundlage</strong>
            </p>
            <p>
              Wir verarbeiten personenbezogene Daten gemäß den Bestimmungen der Europäischen
              Datenschutzgrundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).
            </p>
            <p>
              <strong>a) Zur Erfüllung vertraglicher Pflichten (Art. 6 Abs. 1 b) DSGVO)</strong>
            </p>
            <p>
              Die Verarbeitung von Daten erfolgt zur Begründung von Vertragsverhältnissen sowie zur
              Erbringung unserer vertraglichen Leistungen, aber auch zur Durchführung
              vorvertraglicher Maßnahmen, die auf Anfrage hin erfolgen.
            </p>
            <p>
              <strong>b) Im Rahmen der Interessenabwägung (Art. 6 Abs. 1 f) DSGVO)</strong>
            </p>
            <p>
              <div>
                Über die Nutzung zur eigentlichen Vertragserfüllung hinaus kann es erforderlich
                sein, Ihre Daten zur Wahrung berechtigter Interessen von uns oder Dritten zu
                verarbeiten, z.B.
              </div>
              <ul>
                <li>
                  Gewährleistung der IT-Sicherheit unserer Webseite (z.B. beim Einsatz essentieller
                  Cookies)
                </li>
                <li>
                  Geltendmachung rechtlicher Ansprüche und Verteidigung bei rechtlichen
                  Streitigkeiten
                </li>
              </ul>
            </p>
            <p>
              <strong>
                c) Zur Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 c) DSGVO)
              </strong>
            </p>
            <p>
              In unserem Tätigkeitsbereich bestehen unter Umständen gesetzliche
              Dokumentationspflichten, die eine Speicherung (auch) von personenbezogenen Daten
              erforderlich machen können.
            </p>
            <p>
              <strong>d) Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 a) DSGVO)</strong>
            </p>
            <p>
              Für bestimmte Zwecke (z.B. Übermittlung von personenbezogenen Daten an Dritte und/oder
              in Drittstaaten, Werbezwecke) benötigen wir Ihre Einwilligung. Diese Einwilligung
              fordern wir auch ab, wenn wir Cookies einsetzen, die für den Betrieb der Webseite
              nicht zwingend notwendig sind. Eine Verarbeitung findet in solchen Fällen nur statt,
              wenn und soweit Sie diese Einwilligung ausdrücklich erteilt haben. Sie können Ihre
              Einwilligung jederzeit widerrufen. Der Widerruf berührt nicht die Rechtmäßigkeit der
              Verarbeitung bis zum Zugang des Widerrufs.
            </p>
            <p>
              <strong>e) Für Zwecke des Beschäftigungsverhältnisses, § 26 BDSG</strong>
            </p>
            <p>
              Bewerben Sie sich bei uns, verarbeiten wir Ihre Bewerberdaten auf Grundlage von § 26
              BDSG.
            </p>
            <p>
              <strong>5. Empfänger der Daten</strong>
            </p>
            <p>
              a) Daten, die wir nach Ziffer 3 a) aa) auf unserer Webseite erfassen, werden nicht an
              Dritte übermittelt.
            </p>
            <p>
              b) Daten, die wir mit Ihrer Einwilligung zu Analysezwecke erfassen, werden an die
              jeweils angegebenen verarbeitenden Unternehmen übermittelt. Diese können Daten an
              andere Unternehmen weiterleiten, die in den Datenschutzbestimmungen des verarbeitenden
              Unternehmens genannt sind.
            </p>
            <p>
              c) Daten werden an öffentliche Stellen übersandt, soweit wir hierzu aufgrund einer
              gesetzlichen Regelung oder behördlichen Maßnahme verpflichtet sind.
            </p>
            <p>
              <strong>6. Übermittlung von Daten in ein Drittland</strong>
            </p>
            <p>
              An Empfänger mit Sitz außerhalb der EU und des EWR übermitteln wir in der Regel keine
              personenbezogenen Daten. Daten können in ein Drittland, z.B. die USA, übermittelt
              werden, wenn Sie im Cookie-Banner Analysetools von Google freigeschaltet haben (siehe
              hierzu die Hinweise oben). Eine Übermittlung in ein Drittland erfolgt im Übrigen nur
              nach gesondertem Hinweis und unter Beachtung der Art. 44 ff. DSGVO.
            </p>
            <p>
              <strong>7. Dauer der Speicherung</strong>
            </p>
            <p>
              Wir speichern Ihre Daten grundsätzlich so lange, wie die Speicherung für die Erfüllung
              vertraglicher und gesetzlicher Pflichten erforderlich ist. Sind die Daten hierfür
              nicht mehr erforderlich, werden sie regelmäßig gelöscht.
            </p>
            <p>
              Daten, die wir auf unserer Webseite erfassen, werden spätestens nach 48 Stunden
              gelöscht. Sie bleiben länger gespeichert, soweit und solange wir sie zur Aufdeckung
              von Angriffen auf die Webseite oder zur Ermittlung einer Störungsursache benötigen.
            </p>
            <p>
              Daten, die Sie uns im Rahmen einer Anfrage mitteilen, löschen wir in der Regel 3
              Monate, nachdem wir Ihnen Rückmeldung gegeben haben, wenn Sie sich hierauf nicht mehr
              bei uns melden.
            </p>
            <p>
              Bewerben Sie sich bei uns, dann speichern wir die Daten, die Sie uns zur Verfügung
              gestellt haben, bis zu 6 Monate nach Abschluss des Bewerbungsvorgangs. Mit Ihrer
              Einwilligung, die wir gesondert einholen, bewahren wir Ihre Daten weitere 6 Monate
              auf, um sie ggf. in einem späteren Bewerbungsverfahren zu berücksichtigen. Ihre
              Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Kommt es zu
              einer Anstellung, übernehmen wir Ihre Bewerberdaten in Ihre Personalakte.
            </p>
            <p>
              Daten, die wir im Zusammenhang mit dem Abschluss oder der Durchführung von Verträgen
              erhalten, speichern wir für die Dauer der Vertragslaufzeit und darüber hinaus.
            </p>
            <p>
              <div>Eine Löschung erfolgt nicht, soweit</div>
              <ul>
                <li>Handels- und steuerrechtliche Aufbewahrungspflichten entgegenstehen,</li>
                <li>
                  Daten zum Nachweis von Forderungen aus dem Vertragsverhältnis erhalten bleiben
                  müssen, im Rahmen der regelmäßigen Verjährungsfrist (3 Jahre, § 195 BGB).
                </li>
              </ul>
              <div>
                Soweit eine Löschung nach vorstehendem Satz nicht erfolgt, werden die Daten
                gesperrt. Wir überprüfen die Erforderlichkeit in regelmäßigem Abstand.
              </div>
            </p>
            <p>
              <strong>8. Sicherheit Ihrer Daten</strong>
            </p>
            <p>
              Wir treffen alle angemessenen technisch-organisatorische Maßnahmen, um den Schutz
              Ihrer Daten zu gewährleisten.
            </p>
            <p>
              <strong>9. Ihre Datenschutzrechte</strong>
            </p>
            <p>
              <div>Als „betroffene Person“ haben Sie das Recht auf</div>
              <ul>
                <li>Auskunft nach Art. 15 DSGVO,</li>
                <li>auf Berichtigung nach Art. 16 DSGVO</li>
                <li>auf Löschung nach Art. 17 DSGVO</li>
                <li>auf Einschränkung der Verarbeitung nach Art. 18 DSGVO</li>
                <li>auf Datenübertragbarkeit aus Art. 20 DSGVO.</li>
              </ul>
              <div>
                Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen nach §§ 34
                und 35 BDSG.
              </div>
            </p>
            <p>
              Sie haben auch das Recht, sich bei unserem Datenschutzbeauftragten (siehe unten 12)
              oder bei der zuständigen Datenschutzbehörde zu beschweren (Art. 77 DSGVO i.V. m. § 19
              BDSG).
            </p>
            <p>
              <strong>Widerspruchsrecht</strong>
            </p>
            <p>
              Verarbeiten wir Ihre Daten zur Wahrung berechtigter Interessen (siehe oben), können
              Sie dieser Verarbeitung aus Gründen, die sich aus ihrer besonderen Situation ergeben,
              widersprechen. Wir verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei
              denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
              Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>
            <p>
              <strong>10. Pflicht zur Bereitstellung von Daten</strong>
            </p>
            <p>
              Sie sind gesetzlich nicht verpflichtet, uns Daten zur Verfügung zu stellen. Wenn Sie
              Anfragen an uns richten oder uns beauftragen wollen, ist es jedoch erforderlich, dass
              Sie uns diejenigen Daten mitteilen, die für die Beantwortung der Anfrage bzw.
              Erbringung unserer Leistungen erforderlich sind. Wir benötigen in der Regel Ihren
              Namen und Kontaktdaten, damit wir mit Ihnen korrespondieren könne.
            </p>
            <p>
              <strong>11. Automatisierte Entscheidungsfindung und Profiling</strong>
            </p>
            <p>
              Eine Automatisierte Entscheidungsfindung und/oder Profiling im Sinne von Art. 22 Abs.
              1 und 4 DSGVO finden nicht statt.
            </p>
            <p>
              <strong>12. Fragen zum Datenschutz</strong>
            </p>
            <p>
              Wenn Sie Fragen oder Anmerkungen zum Thema Datenschutz haben, senden Sie uns eine E-
              Mail an datenschutz(at)jade-concept.de
            </p>
            <p>
              <strong>13. Änderungen der Datenschutzerklärung</strong>
            </p>
            <p>
              Wir behalten uns vor, die Regelungen unserer Datenschutzerklärung von Zeit zu Zeit
              anzupassen. Ältere Fassungen der Datenschutzerklärung können Sie über die o.g.
              Mailadresse bei uns anfordern.
            </p>
            <p>V 2.0 Varel im Oktober 2021</p>
          </Container>
        </div>
      </Layout>
    </div>
  );
};

export default DataProtection;
