import { FunctionComponent } from 'react';

import './Map.scss';

import { useSelector } from 'react-redux';

import mapImage from '../../../assets/image/start/map.svg';

const Map: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="home-map">
      {/* <div className="headline">{translation['home.map.headline']}</div> */}
      <div className="wrapper">
        <img src={mapImage} alt="Deutschland-Karte" />
      </div>
      <div className="question">{translation['home.map.question']}</div>
      <div className="cta-wrapper">
        <a href="mailto:energie@jade-concept.de" className="cta">
          {translation['home.map.cta']}
        </a>
      </div>
    </div>
  );
};

export default Map;
