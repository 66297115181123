import { FunctionComponent } from 'react';

import './Section.scss';

import { useSelector } from 'react-redux';

import sectionBulletpointIcon from '../../../../assets/image/references/aufzaehlungIcon.svg';
import Slider from '../../../Slider/Slider';

type Props = {
  index: number;
  bulletPointAmount: number;
  sliderItemAmount: number;
};

const Section: FunctionComponent<Props> = ({ index, bulletPointAmount, sliderItemAmount }) => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div className="references-overview-section">
      <div className="headline">{translation[`references.overview.section.${index}.headline`]}</div>
      <div className="bulletpoints-wrapper">
        <div className="bulletpoints">
          {[...new Array(bulletPointAmount)].map((i, subIndex) => (
            <div className="item" style={{ marginLeft: `${subIndex * 50}px` }}>
              <img src={sectionBulletpointIcon} alt="Section Bulletpoint" />
              {translation[`references.overview.section.${index}.bulletpoints.${subIndex + 1}`]}
            </div>
          ))}
        </div>
      </div>
      <div className="slider-wrapper">
        <Slider
          items={[...new Array(sliderItemAmount)].map((i, subIndex) => (
            <div className="styled-slider-item">
              <div className="text">
                {
                  translation[
                    `references.overview.section.${index}.slider-item.${subIndex + 1}.text`
                  ]
                }
              </div>
              <div className="author">
                {
                  translation[
                    `references.overview.section.${index}.slider-item.${subIndex + 1}.author`
                  ]
                }
              </div>
            </div>
          ))}
        />
      </div>
    </div>
  );
};

export default Section;
