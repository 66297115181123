import { getUUID, googleAPIKey } from '../../../../utility';
import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerIcon from '../../../../assets/image/marker.png';
import './GoogleMap.scss';
import { useSelector } from 'react-redux';

const Marker = ({ data }) => {
  const translation = useSelector(state => state.translation);
  const [showOverlay, setShowOverlay] = useState(false);
  const dataArray = Array.isArray(data.data) ? data.data : [data];
  return (
    <div className="google-maps-marker">
      {showOverlay && (
        <div className="overlay" onClick={() => setShowOverlay(false)}>
          {dataArray.map(item => {
            return (
              <div className="item" key={getUUID()}>
                {item.state && (
                  <div className="prop">
                    <div className="key">{translation['google-map.state']}</div>
                    <div className="value">{item.state}</div>
                  </div>
                )}
                {item.manufacturer && (
                  <div className="prop">
                    <div className="key">{translation['google-map.manufacturer']}</div>
                    <div className="value">{item.manufacturer}</div>
                  </div>
                )}
                {item.type && (
                  <div className="prop">
                    <div className="key">{translation['google-map.type']}</div>
                    <div className="value">{item.type}</div>
                  </div>
                )}
                {item.totalPower && (
                  <div className="prop">
                    <div className="key">{translation['google-map.totalPower']}</div>
                    <div className="value">{item.totalPower}</div>
                  </div>
                )}
                {item.finalization && (
                  <div className="prop">
                    <div className="key">{translation['google-map.finalization']}</div>
                    <div className="value">{item.finalization}</div>
                  </div>
                )}
                {item.name && (
                  <div className="prop">
                    <div className="key">{translation['google-map.name']}</div>
                    <div className="value">{item.name}</div>
                  </div>
                )}
                {item.purpose && (
                  <div className="prop">
                    <div className="key">{translation['google-map.purpose']}</div>
                    <div className="value">{item.purpose}</div>
                  </div>
                )}
                {item.location && (
                  <div className="prop">
                    <div className="key">{translation['google-map.location']}</div>
                    <div className="value">{item.location}</div>
                  </div>
                )}
                {item.company && (
                  <div className="prop">
                    <div className="key">{translation['google-map.company']}</div>
                    <div className="value">{item.company}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className="icon-wrapper">
        <img src={MarkerIcon} alt={data.name} onClick={() => setShowOverlay(true)} />
      </div>
    </div>
  );
};

export default function SimpleMap({ data, index }) {
  const defaultProps = {
    center: {
      lat: parseFloat(data.lat),
      lng: parseFloat(data.lng)
    },
    zoom: data.zoom || 7.5
  };

  return (
    // Important! Always set the container height explicitly
    <div
      id={`google-map-${index}`}
      className="google-map"
      style={{ height: '500px', width: '100%' }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleAPIKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {data.zoom ? (
          data.data.map(marker => {
            return <Marker key={getUUID()} lat={marker.lat} lng={marker.lng} data={marker} />;
          })
        ) : (
          <Marker key={getUUID()} lat={data.lat} lng={data.lng} data={data} />
        )}
      </GoogleMapReact>
    </div>
  );
}
