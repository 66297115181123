import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import "./Requirements.scss";

const Requirements = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <div id="photovoltaic-requirements">
      <Container>
        <div className="subheadline">
          {translation["photovoltaic.requirements.subheadline"]}
        </div>
        <div className="headline">
          {translation["photovoltaic.requirements.headline"]}
        </div>
        <div className="text">
          {translation["photovoltaic.requirements.text"]}
        </div>
      </Container>
      <div className="items">
        <div className="item">
          {translation["photovoltaic.requirements.items.1"]}
        </div>
        <div className="item">
          {translation["photovoltaic.requirements.items.2"]}
        </div>
        <div className="item">
          {translation["photovoltaic.requirements.items.3"]}
        </div>
        <div className="item">
          {translation["photovoltaic.requirements.items.4"]}
        </div>
      </div>
    </div>
  );
};

export default Requirements;
