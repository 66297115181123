import { FunctionComponent } from 'react';

import './Intro.scss';

import { useSelector } from 'react-redux';

const Intro: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="participation-intro">
      <div className="text-wrapper">
        <div className="center-wrapper">
          <div className="text">{translation['participation.intro.text']}</div>
        </div>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Intro;
