import { FunctionComponent } from 'react';

import { useSelector } from 'react-redux';
import './Plugin.scss';

const Plugin: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="contact-plugin">
      {/* <div className="placeholder-wrapper">
            <div className="placeholder">PLUGIN</div>
        </div> */}
      <div className="text" style={{ paddingTop: '50px' }}>
        <p className="highlight">{translation['contact.intro.text.1']}</p>
        <p>{translation['contact.intro.text.2']}</p>
        <p>
          {translation['contact.intro.text.3']}
          <br />
          {translation['contact.intro.text.4']}
        </p>
        <p>
          <a href="mailto:energie@jade-concept.de">{translation['contact.intro.text.5']}</a>
          <br />
          <a href="tel:+49 (0) 44 51 80 96 55">{translation['contact.intro.text.6']}</a>
          <br />
          {translation['contact.intro.text.7']}
        </p>
      </div>
    </div>
  );
};

export default Plugin;
