import { FunctionComponent } from 'react';

import './Intro.scss';

import { useSelector } from 'react-redux';

const Intro: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="data-protection-intro">
      <div className="scroll-down"></div>
      <div className="text-wrapper">
        <div className="center-wrapper">
          <div className="intro-text">{translation['dataProtection.intro.headline']}</div>
        </div>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Intro;
