import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import './Advantages.scss';
import checkImage from '../../../assets/image/photovoltaic/check.png';

const Advantages = () => {
  const translation = useSelector(s => s.translation);
  return (
    <div id="photovoltaic-advantages">
      <Container>
        <div className="subheadline">{translation['photovoltaic.advantages.subheadline']}</div>
        <div className="headline">{translation['photovoltaic.advantages.headline']}</div>
        <div className="items">
          <div className="item">
            <div className="image">
              <img src={checkImage} alt="check" />
            </div>
            <div className="text">{translation['photovoltaic.advantages.items.1']}</div>
          </div>
          <div className="item">
            <div className="image">
              <img src={checkImage} alt="check" />
            </div>
            <div className="text">{translation['photovoltaic.advantages.items.2']}</div>
          </div>
          <div className="item">
            <div className="image">
              <img src={checkImage} alt="check" />
            </div>
            <div className="text">{translation['photovoltaic.advantages.items.3']}</div>
          </div>
          <div className="item">
            <div className="image">
              <img src={checkImage} alt="check" />
            </div>
            <div className="text">{translation['photovoltaic.advantages.items.4']}</div>
          </div>
          <div className="item">
            <div className="image">
              <img src={checkImage} alt="check" />
            </div>
            <div className="text">{translation['photovoltaic.advantages.items.5']}</div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Advantages;
