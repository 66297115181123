import "./Navigation.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import burgerIcon from "../../../assets/image/burger.png";

const Header = ({ menuSelect }) => {
  const translation = useSelector((state) => state.translation);
  const history = useHistory();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const redirect = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    history.push(e.target.attributes.href.value);
  };
  const openMenu = () => setShowMobileMenu(!showMobileMenu);

  return (
    <div id="navigation">
      <div className="mobile-toggle">
        <div className="item" onClick={openMenu}>
          <img src={burgerIcon} alt="Menu" />
        </div>
      </div>
      <div className={`item-list ${showMobileMenu && "show"}`}>
        <a
          className={`item${menuSelect === "home" ? " selected" : ""}`}
          href="/"
          onClick={redirect}
        >
          {translation["navigation.home"]}
        </a>
        <a
          className={`item${menuSelect === "photovoltaic" ? " selected" : ""}`}
          href="/photovoltaic"
          onClick={redirect}
        >
          {translation["navigation.photovoltaic"]}
        </a>
        <a
          className={`item${menuSelect === "solutions" ? " selected" : ""}`}
          href="/solutions"
          onClick={redirect}
        >
          {translation["navigation.solutions"]}
        </a>
        {/* <a className={`item${menuSelect === 'references' ? ' selected' : ''}`} href="/references" onClick={redirect}>{translation['navigation.references']}</a> */}
        <a
          className={`item${menuSelect === "participation" ? " selected" : ""}`}
          href="/participations"
          onClick={redirect}
        >
          {translation["navigation.participation"]}
        </a>
        <a
          className={`item${menuSelect === "gallery" ? " selected" : ""}`}
          href="/gallery"
          onClick={redirect}
        >
          {translation["navigation.gallery"]}
        </a>
        <a
          className={`item${menuSelect === "about-us" ? " selected" : ""}`}
          href="/about-us"
          onClick={redirect}
        >
          {translation["navigation.about-us"]}
        </a>
        <a
          className={`item${menuSelect === "contact" ? " selected" : ""}`}
          href="/contact"
          onClick={redirect}
        >
          {translation["navigation.contact"]}
        </a>
      </div>
    </div>
  );
};

export default Header;
