import { FunctionComponent } from 'react';

import './ThreeText.scss';

import { useSelector } from 'react-redux';

import groupImage from '../../../assets/image/start/group.svg';
import recycleImage from '../../../assets/image/start/recycle.svg';
import turbineImage from '../../../assets/image/start/turbine.svg';

const ThreeText: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="home-threetext">
      {/* <div className="headline">{translation['home.threetext.headline']}</div> */}
      <div className="block-wrapper">
        <div className="block">
          <div className="image">
            <img src={turbineImage} alt="Turbine" />
          </div>
          <div className="headline">{translation['home.threetext.text.1.headline']}</div>
          <div className="text">{translation['home.threetext.text.1.text']}</div>
        </div>
        <div className="block">
          <div className="image">
            <img src={recycleImage} alt="Recycle" />
          </div>
          <div className="headline">{translation['home.threetext.text.2.headline']}</div>
          <div className="text">{translation['home.threetext.text.2.text']}</div>
        </div>
        <div className="block">
          <div className="image">
            <img src={groupImage} alt="Gruppe" />
          </div>
          <div className="headline">{translation['home.threetext.text.3.headline']}</div>
          <div className="text">{translation['home.threetext.text.3.text']}</div>
        </div>
      </div>
    </div>
  );
};

export default ThreeText;
