import { FunctionComponent } from 'react';

import './Intro.scss';

import { useSelector } from 'react-redux';

const Intro: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="home-intro">
      <div className="text-wrapper">
        <div className="center-wrapper">
          <div className="text">
            {translation['home.intro.text.0']}
            <br />
            {translation['home.intro.text.1']}
            <br />
            {translation['home.intro.text.2']}
            <br />
            {translation['home.intro.text.3']}
          </div>
        </div>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Intro;
