const translations = {
  'home.intro.text.0': 'Jade Concept',
  'home.intro.text.1': 'Wir betreuen keine',
  'home.intro.text.2': 'Windkraftanlagen,',
  'home.intro.text.3': 'Wir betreuen die Betreiber.',

  'home.threetext.headline': '01 Headline.',
  'home.threetext.text.1.headline': 'Energie',
  'home.threetext.text.1.text':
    'Wind- und Sonnenenergie sind die wichtigsten erneuerbaren Energieträger. Durch effizientere Technologien werden diese Energieträger mehr und mehr zu einer der wichtigsten Säulen der Stromversorgung.',
  'home.threetext.text.2.headline': 'Nachhaltigkeit',
  'home.threetext.text.2.text':
    'Mit Blick auf die Klimaerwärmung und der Energieversorgungssicherheit werden diese Energieträger mehr und mehr zu den wichtigsten Säulen der Energieversorgung. Gehen Sie mit uns den Weg in eine saubere Zukunft!',
  'home.threetext.text.3.headline': 'Norddeutschland',
  'home.threetext.text.3.text':
    'Wir sind Ihr Partner für die Energiewende in Norddeutschland! Wir begleiten Sie durch alle Projektschritte und stehen Ihnen stets unterstützend zur Seite. Werden Sie Teil unseres starken Netzwerkes!',

  'home.process.headline': 'Wie läuft ein gemeinsames Projekt mit Jade Concept ab?',
  'home.process.bulletpoint.1.headline': 'KENNENLERNEN & ERSTGESPRÄCH',
  'home.process.bulletpoint.1.text':
    'In einem unverbindlichen Erstgespräch lernen wir uns zunächst kennen und bilden somit eine ehrliche und vertrauensvolle Basis für eine langfristige Zusammenarbeit auf Augenhöhe. Wir klären Ihre Fragen und beraten Sie umfangreich. Von nun an stehen wir Ihnen bei allen weiteren Schritten mit unserer langjährigen Expertise zur Seite.',
  'home.process.bulletpoint.2.headline': 'ANALYSE DES PLANGEBIETES',
  'home.process.bulletpoint.2.text':
    'Im ersten Schritt beginnen wir damit, das geplante Gebiet genau zu analysieren und schaffen somit eine wichtige Grundlage für das Projekt.',
  'home.process.bulletpoint.3.headline': 'GEMEINSAME ERARBEITUNG VON UMSETZUNGSMÖGLICHKEITEN',
  'home.process.bulletpoint.3.text':
    'Sobald diese ersten Analysen abgeschlossen sind, gehen wir gemeinsam mit Ihnen in die Planung, wobei wir konkrete Umsetzungsmöglichkeiten für das gemeinsame Projekt entwickeln.',
  'home.process.bulletpoint.4.headline': 'GESTALTUNG EINER GEMEINSAMEN PROJEKTGESELLSCHAFT',
  'home.process.bulletpoint.4.text':
    'Im nächsten Schritt gestalten wir gemeinsam unsere Projektgesellschaft, mit welcher Sie sich zu 100% identifizieren können und in welche Sie sich als Partner auf Augenhöhe einbringen.',
  'home.process.bulletpoint.5.headline':
    'GRÜNDUNG DER GESELLSCHAFT UND ERSTELLUNG VON NUTZUNGSVERTRÄGEN',
  'home.process.bulletpoint.5.text':
    'Nach Abschluss der Planungen gründen wir nun gemeinsam unsere Projektgesellschaft und erstellen umgehend alle notwendigen Nutzungsverträge.',
  'home.process.bulletpoint.6.headline': 'EINHOLUNG VON GUTACHTEN',
  'home.process.bulletpoint.6.text':
    'Im letzten Schritt vor dem offiziellen Projektstart müssen nun noch verschiedene Gutachten und Genehmigungen eingeholt werden. Sobald diese vorliegen, steht dem erfolgreichen Projekt nichts mehr im Wege.',

  // 'home.map.headline': '02 Headline.',
  'home.map.question': 'Haben wir Ihre Interesse geweckt?',
  'home.map.cta': 'Anfrage senden',

  'home.percent.headline': '75% DER WINDPARKS IN EIGENTUM DER GRUNDSTÜCKSEIGENTÜMER.',
  'home.percent.text.1': 'Wir betreuen keine',
  'home.percent.text.2': 'Windkraftanlagen,',
  'home.percent.text.3': 'Wir betreuen die Betreiber.',

  'solutions.intro.headline': 'UNSER LEISTUNGSANGEBOT.',

  // 'solutions.process.headline': '04 Headline.',
  'solutions.process.bulletpoint.1.headline': 'Vorplanung',
  'solutions.process.bulletpoint.1.text':
    'Standortvorprüfung, allgemeine Eignung, Flächensicherung, Verträglichkeitsprüfung Raum und Umwelt',
  'solutions.process.bulletpoint.2.headline': 'Strukturierung von Beteiligungsmodellen',
  'solutions.process.bulletpoint.2.text':
    'Beteiligung zum Beispiel von Landwirten, Bürgern und Kommunen',
  'solutions.process.bulletpoint.3.headline': 'Standortentwicklung',
  'solutions.process.bulletpoint.3.text':
    'Konfigurationsentwicklung, Netzplanung, Auswahl des optimalen Anlagentyps, Wirtschaftlichkeitsanalysen',
  'solutions.process.bulletpoint.4.headline': 'Baugenehmigung',
  'solutions.process.bulletpoint.4.text':
    'Beibringung aller erforderlichen Unterlagen, Gutachten usw. Ausgleichs- und Ersatzmaßnahmen',
  'solutions.process.bulletpoint.5.headline': 'Einkauf und Finanzierung',
  'solutions.process.bulletpoint.5.text':
    'Anlagenportfolio mit allen führenden Herstellern, Prospekterstellung, Zusammenarbeit mit spezialisierten Banken, gern auch mit Ihrem Institut vor Ort',
  'solutions.process.bulletpoint.6.headline': 'Realisierung',
  'solutions.process.bulletpoint.6.text':
    'Koordination der Bauplanung und der Bauausführung, Netzanschluss, Übergabestation, Bauaufsicht, Inbetriebnahme',
  // 'solutions.process.bulletpoint.7.headline': 'Kaufmännische Geschäftsführung',
  // 'solutions.process.bulletpoint.7.text': 'Geschäftsführung, Direktvermarktung, Buchhaltung, Berichtswesen',
  // 'solutions.process.bulletpoint.8.headline': 'Technische Geschäftsführung',
  // 'solutions.process.bulletpoint.8.text': 'Performancekontrolle, Überwachung Leistungskennlinie und Verfügbarkeit',

  'solutions.dome.headline': 'EIGENTÜMERWINDPARK.',
  'solutions.dome.text.1':
    'Der Klimawandel ist eine der zentralen Herausforderungen unserer Zeit. Es müssen grundlegende Veränderungen herbeigeführt werden, um unseren Planeten nachhaltig zu schützen. Gleichzeitig muss der weltweit steigende Energiebedarf gedeckt werden, der damit einher geht.',
  'solutions.dome.text.2': 'Energiewende umsetzen – gemeinsam Chancen nutzen.',
  'solutions.dome.text.3':
    'Genau das ist möglich. Erneuerbare Energien sind durch immer effizienter werdende Technologien mehr und mehr in der Lage, die konventionellen Energieträger wie Kohle, Öl und Gas sowie auch den Strom aus der atomaren Erzeugung in erheblichem Maße bis nahezu vollständig zu ersetzen. Die Windenergie spielt dabei eine zentrale Rolle.',
  'solutions.dome.text.4':
    'Windparks, die auch als Eigentümerwindparks geführt werden, beziehen den Eigentümer direkt mit ein und schaffen dadurch verstärkt das Bewusstsein, aktiv einen Beitrag zur Energiewende zu leisten.',
  'solutions.dome.text.4.1':
    'Uns ist es wichtig, dass die Eigentümer ihren Windpark behalten und direkt an der Wertschöpfung durch die Erzeugung sauberen Stroms teilhaben.',
  'solutions.dome.text.5':
    'Die Eigentümer werden direkt an der Projektgesellschaft beteiligt. Dafür haben wir individuell gestaltbare Beteiligungskonzepte entwickelt, die auf eine langjährige Zusammenarbeit ausgelegt sind.',
  'solutions.dome.text.6':
    'Mit unserer Expertise stehen wir unseren Partnern stets beratend zur Seite und begleiten sie durch alle Projektschritte. Die Entscheidungsfreiheit bleibt dabei beim Eigentümer.',
  'solutions.dome.text.7':
    'Somit bieten wir unseren beteiligten Partnern eine Teilhabe an einer eigenen, dezentralen und zukunftsfähigen Energieerzeugung, die ökologisches Handeln und wirtschaftlichen Erfolg sinnvoll verbindet. Gerne entwickeln wir mit Ihnen und Ihrer Kommune ein passgenaues Konzept.',

  'photovoltaic.intro.headline.1': 'Photovoltaik',
  'photovoltaic.intro.headline.2': 'Freiflächenanlagen',
  'photovoltaic.intro.headline.3': '(PVA)',
  'photovoltaic.overview.subheadline': 'Übersicht',
  'photovoltaic.overview.headline': 'Gesellschaft und Referenzen',
  'photovoltaic.overview.text.1.headline': 'POLITISCHES TOPTHEMA',
  'photovoltaic.overview.text.1.text':
    'Transformation der fossilen Energiewirtschaft in die Klimaneutralität',
  'photovoltaic.overview.text.2.headline': 'GESTEIGERTE DYNAMIK',
  'photovoltaic.overview.text.2.text':
    'Durch das Klimaabkommen 2015 und Urteil des Bundesverfassungsgerichtes',
  'photovoltaic.overview.text.3.headline': 'ENTSCHLOSSENHEIT',
  'photovoltaic.overview.text.3.text':
    'Hohe politische Entschlossenheit zur Durchsetzung von nationalen Klimazielen',
  'photovoltaic.separator.text': 'Deutschland soll früher klimaneutral werden',
  'photovoltaic.goals.headline': 'KLIMAZIELE DER BUNDESREGIERUNG',
  'photovoltaic.goals.text.1.text.1':
    '+ Verstärkter Druck zur schnellen Umsetzung durch neue Bundesregierung erwartet.',
  'photovoltaic.goals.text.1.text.2':
    '+ Senkung der Barrieren, schnellere Genehmigungen, höhere Ausschreibungsvolumen',
  'photovoltaic.goals.text.2.headline': 'Treibhausgasemission',
  'photovoltaic.goals.text.2.text.1': 'Bis 2030: 65% weniger CO2 (bislang 55%)',
  'photovoltaic.goals.text.2.text.2': 'Bis 2040: 88% weniger CO2',
  'photovoltaic.goals.text.2.text.3': '2045: Klimaneutralität (bislang 2050)',
  'photovoltaic.goals.text.3.headline': 'CO2-Emissionsmengen',
  'photovoltaic.goals.text.3.text':
    'Zulässige jährliche CO2-Emissionenmengen für einzelne Sektoren wie Energiewirtschaft, Industrie, Verkehr oder Gebäudebereich werden abgesenkt.',
  'photovoltaic.numberation.subheadline':
    'Ausbauziele Niedersachsen für Freiflächenphotovoltaikanlagen',
  'photovoltaic.numberation.headline': 'PHOTOVOLTAIK AUF FREIFLÄCHEN.',
  'photovoltaic.numberation.item.1.1': '65 GW',
  'photovoltaic.numberation.item.1.2': 'bis 2035',
  'photovoltaic.numberation.item.2': '15 GW auf Freiflächen entsprechend 15000ha',
  'photovoltaic.numberation.item.2.1': '15 GW',
  'photovoltaic.numberation.item.2.2': 'auf Freiflächen',
  'photovoltaic.numberation.item.2.3': 'entsprechend 15000ha',
  'photovoltaic.numberation.item.3.1': 'Notwendiges Ausschreibungsvolumen',
  'photovoltaic.numberation.item.3.2': '800 MW / Jahr',
  'photovoltaic.numberation.item.4.1': 'Planung u.a. möglich',
  'photovoltaic.numberation.item.4.2': 'in benachteiligten Gebieten',
  'photovoltaic.numberation.item.4.3': '150 MW jährlich',
  'photovoltaic.numberation.item.5.1': 'Entlang vorhandener Infrastruktur, Autobahn,',
  'photovoltaic.numberation.item.5.2': 'Eisenbahn entspr. Ausschreibungsvolumen',
  'photovoltaic.numberation.item.5.3': '(200m inkl. 15m Puffer)',
  'photovoltaic.presentation-banner.headline': 'Vorstellung des Projektes.',
  'photovoltaic.presentation-banner.subheadline': 'Gesellschafter und Referenzen',
  'photovoltaic.presentation.subheadline': 'Vorstellung des Projektes',
  'photovoltaic.presentation.headline': 'RÄUMLICHE DARSTELLUNG.',
  'photovoltaic.advantages.subheadline': 'Vorstellung des Projektes',
  'photovoltaic.advantages.headline': 'Vorteile für den Grundstückeigentümer',
  'photovoltaic.advantages.items.1': 'Sichere Pachten über die Laufzeit 20 Jahre / 30 Jahre',
  'photovoltaic.advantages.items.2': 'Wertschöpfung bleibt in der Region',
  'photovoltaic.advantages.items.3':
    'Pachtverträge mit hoher Sicherheit für den Pächter z.B. bezüglich des Rückbaus',
  'photovoltaic.advantages.items.4':
    'Interessantes Investment mit aussichtsreicher und nachhaltiger Rendite',
  'photovoltaic.advantages.items.5':
    'Grundstückseigentümer entscheiden, was auf der Fläche entsteht',
  'photovoltaic.construction.subheadline': 'Vorstellung des Projektes',
  'photovoltaic.construction.headline': 'Aufbau und Eingriff',
  'photovoltaic.construction.item.1.headline': 'Aufbau',
  'photovoltaic.construction.item.1.text.1': '+ Planfläche: ca. 40 ha',
  'photovoltaic.construction.item.1.text.2': '+ 1 ha = 1 MWp',
  'photovoltaic.construction.item.1.text.3': '+ Modultische',
  'photovoltaic.construction.item.1.text.4': '+ Schraub- und / oder Rammfundamente',
  'photovoltaic.construction.item.1.text.5': '+ Rammtiefe: 2 m',
  'photovoltaic.construction.item.1.text.6': '+ Reihenabstand: 2.5 m – 4.5 m',
  'photovoltaic.construction.item.2.headline': 'Eingriff',
  'photovoltaic.construction.item.2.text.1': '+ Versiegelungsanteil: ca. 2 %',
  'photovoltaic.construction.item.2.text.2':
    '+ überdeckte Fläche (Projektion der Modulfläche): ca. 30-35 % der Gesamtprojektfläche',
  'photovoltaic.construction.item.3.headline': 'Sicherung',
  'photovoltaic.construction.item.3.text.1':
    '+ Die Projektfläche wird mit einem min. 2.00m hohen Zaun geschützt. Es werden auch Alarmanlagen und Überwachungssysteme installiert.',
  'photovoltaic.construction.item.3.text.2':
    '+ Der Abstand zwischen Zaun und Modultischen beträgt durchschnittlich 5m.',
  'photovoltaic.requirements.subheadline': 'Vorstellung des Projektes',
  'photovoltaic.requirements.headline': 'Planungsrechtliche Erfordernisse',
  'photovoltaic.requirements.text':
    'Errichtung einer Photovoltaikfreiflächenanlage ist abhängig von der Zustimmung der jeweiligen Standortkommune Parallelverfahren: geänderte Nutzungsbestimmung/ Anpassung Flächennutzungsplan',
  'photovoltaic.requirements.items.1':
    'Aufstellungsbeschluss der Kommune für den Bebauungsplan (und Flächennutzung)',
  'photovoltaic.requirements.items.2': 'Abschluss städtebaulicher Vertrag',
  'photovoltaic.requirements.items.3': 'Durchführung Bauleitplanverfahren',
  'photovoltaic.requirements.items.4':
    'Satzungsbeschluss und Veröffentlichung der beschlossenen Pläne',

  'references.intro.headline': 'UNSERE REFERENZEN.',

  'references.bulletpoints.1': '200 Windenergieanlagen',
  'references.bulletpoints.2': '294,1 MW Nennleistung',
  'references.bulletpoints.3': '404,1 Mio. Euro Investitionsvolumen',
  'references.bulletpoints.highlight': '45% Bürgerbeteiligung',

  'references.slider.item.1':
    '»Neben unseren eigenen Erfahrungen in der Projektierung und Realisation bauen wir seit 1996 auf die professionelle Zusammenarbeit mit der Jade Unternehmensgruppe. Wir werden dort gut beraten.« Johann Ortgies',
  'references.slider.item.2':
    '»Neben unseren eigenen Erfahrungen in der Projektierung und Realisation bauen wir seit 1996 auf die professionelle Zusammenarbeit mit der Jade Unternehmensgruppe. Wir werden dort gut beraten.« Johann Ortgies',
  'references.slider.item.3':
    '»Neben unseren eigenen Erfahrungen in der Projektierung und Realisation bauen wir seit 1996 auf die professionelle Zusammenarbeit mit der Jade Unternehmensgruppe. Wir werden dort gut beraten.« Johann Ortgies',

  'references.overview.headline': '05 HEADLINE.',
  'references.overview.section.1.headline': 'ERSTER WINDPARK ÜBERSCHRIFT',
  'references.overview.section.1.bulletpoints.1':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.1.bulletpoints.2':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.1.bulletpoints.3':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.1.bulletpoints.4':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.1.slider-item.1.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.1.slider-item.1.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.1.slider-item.2.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.1.slider-item.2.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.1.slider-item.3.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.1.slider-item.3.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.1.slider-item.4.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.1.slider-item.4.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.2.headline': 'ZWEITER WINDPARK ÜBERSCHRIFT',
  'references.overview.section.2.bulletpoints.1':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.2.bulletpoints.2':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.2.bulletpoints.3':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.2.bulletpoints.4':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
  'references.overview.section.2.slider-item.1.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.2.slider-item.1.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.2.slider-item.2.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.2.slider-item.2.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.2.slider-item.3.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.2.slider-item.3.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',
  'references.overview.section.2.slider-item.4.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse“',
  'references.overview.section.2.slider-item.4.author': 'KUNDE KUNDE, FIRMA FIRMA, 2019.',

  'aboutus.intro.headline': 'Über uns.',

  'aboutus.main.headline': '06 Headline.',
  'aboutus.main.text.1.headline': 'Headline',
  'aboutus.main.text.1.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit',
  'aboutus.main.text.2.headline': 'Headline',
  'aboutus.main.text.2.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit',
  'aboutus.main.text.3.headline': 'Headline',
  'aboutus.main.text.3.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit',
  'aboutus.main.text.4.headline': 'Headline',
  'aboutus.main.text.4.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit',
  'aboutus.main.text.cta': 'Mehr Informationen',
  'aboutus.main.person.headline': 'Unternehmensleitung',
  'aboutus.main.person.1.name': 'Andreas Neumann',
  'aboutus.main.person.1.paragraph.1': 'Geschäftsführer der Jade Concept seit 1999',
  'aboutus.main.person.2.name': 'Jan Holst',
  'aboutus.main.person.2.paragraph.1': 'Geschäftsführer der Jade Concept seit 2015',

  'aboutus.main.team.headline': 'Das Team.',
  'aboutus.main.team.text':
    '„Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit',

  'contact.intro.headline': 'Kontakt.',
  'contact.intro.text.1': 'Kontakt:',
  'contact.intro.text.2': 'Jade Concept Kapitalanlageberatungsgesellschaft mbH,',
  'contact.intro.text.3': 'Friedrich-Ebert-Straße 28',
  'contact.intro.text.4': '26316 Varel',
  'contact.intro.text.5': 'E-Mail: energie@jade-concept.de',
  'contact.intro.text.6': 'Telefon: +49 (0) 44 51 80 96 55',
  'contact.intro.text.7': 'Fax: +49 (0) 44 51 80 96 59',

  'participation.intro.text': 'Beteiligungen.',
  'participation.soll-ist-vergleich.download': 'Der Soll-/Istvergleich ist nicht verfügbar.',
  'participation.soll-ist-vergleich.label':
    'Bitte entnehmen Sie die Daten den technischen Berichten.',
  'participation.park.download-cta': 'Downloads',
  'participation.park.birkholz.name': 'Windpark Birkholz',
  'participation.park.broderstorf-2.name': 'Windpark Broderstorf II',
  'participation.park.friedland-3.name': 'Windpark Friedland III',
  'participation.park.gremersdorf.name': 'Windpark Gremersdorf',
  'participation.park.landin.name': 'Windpark Landin',
  'participation.park.lindau-russdorf.name': 'Windpark Lindau / Rußdorf',
  'participation.park.lindau-russdorf.name.lindau': 'Windpark Lindau',
  'participation.park.lindau-russdorf.name.russdorf': 'Windpark Rußdorf',
  'participation.park.radegast.name': 'Windpark Radegast',
  'participation.park.wismar.name': 'Windpark Wismar',
  'participation.park.bassens-2.name': 'Windpark Bassens II',
  'participation.park.close-cta': 'Schließen',

  'gallery.collection.new': 'Neuplanung',
  'gallery.collection.bollenhagen': 'Bollenhagen',
  'gallery.collection.kroegershamm': 'Krögershamm',
  'gallery.collection.weuh-werd': 'Neuh-Werd',
  'gallery.intro.headline': 'Referenzen.',

  'navigation.home': 'Home',
  'navigation.photovoltaic': 'Photovoltaik',
  'navigation.participation': 'Beteiligungen',
  'navigation.gallery': 'Referenzen',
  'navigation.solutions': 'Leistungsangebot',
  'navigation.references': 'Referenzen',
  'navigation.about-us': 'Über uns',
  'navigation.contact': 'Kontakt',

  'footer.imprint': 'Impressum',
  'footer.data-protection': 'Datenschutz',

  'company-name': 'Jade Group',

  'imprint.intro.headline': 'Impressum.',

  'dataProtection.intro.headline': 'Datenschutz.',

  'google-map.state': 'Bundesland: ',
  'google-map.manufacturer': 'Hersteller: ',
  'google-map.type': 'Anlagentyp: ',
  'google-map.totalPower': 'Gesamtleistung: ',
  'google-map.finalization': 'Fertigstellung: ',
  'google-map.name': 'Windpark: ',
  'google-map.purpose': 'Zweck: ',
  'google-map.location': 'Standort: ',
  'google-map.company': 'Unternehmen: '
};

export const langKey = 'DE';

export default translations;
