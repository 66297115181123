import { FunctionComponent } from 'react';

import './Imprint.scss';

import Container from 'react-bootstrap/Container';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';

const Imprint: FunctionComponent = () => {
  return (
    <div id="imprint">
      <Layout>
        <Intro />
        <div className="text">
          <Container>
            <p>
              <strong>Jade Concept Kapitalanlageberatungsgesellschaft mbH</strong>
            </p>

            <p>
              Friedrich-Ebert-Straße 28 <br />
              26316 Varel
            </p>
            <p>
              Telefon: +49 (0) 44 51 80 96 55 <br /> Fax: +49 (0) 44 51 80 96 59
            </p>
            <p>
              E-Mail: energie@jade-concept.de <br /> Internet: www.jade-concept.de
            </p>
            <p>
              Registergericht: Amtsgericht Oldenburg <br /> Registernummer: HRB130993
            </p>
            <p>
              Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
              ausschließlich deren Betreiber verantwortlich.
            </p>
            <p>Bildnachweis: privat und dreamstime.com</p>
          </Container>
        </div>
      </Layout>
    </div>
  );
};

export default Imprint;
