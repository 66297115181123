import "./Goals.scss";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import waveImg from "../../../assets/image/photovoltaic/wave.png";

const Goals = () => {
  const translation = useSelector((state) => state.translation);

  return (
    <Container>
      <div id="photovoltaic-goals">
        <div className="content">
          <div className="headline">
            {translation["photovoltaic.goals.headline"]}
          </div>
          <div className="text">
            <div className="text">
              {translation["photovoltaic.goals.text.1.text.1"]}
            </div>
            <div className="text">
              {translation["photovoltaic.goals.text.1.text.2"]}
            </div>
          </div>
          <div className="text">
            <div className="headline">
              {translation["photovoltaic.goals.text.2.headline"]}
            </div>
            <div className="text">
              <ul>
                <li>{translation["photovoltaic.goals.text.2.text.1"]}</li>
                <li>{translation["photovoltaic.goals.text.2.text.2"]}</li>
                <li>{translation["photovoltaic.goals.text.2.text.3"]}</li>
              </ul>
            </div>
          </div>
          <div className="text">
            <div className="headline">
              {translation["photovoltaic.goals.text.3.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.goals.text.3.text"]}
            </div>
          </div>
        </div>
        <div className="image">
          <img src={waveImg} alt="Pannel Wave" />
        </div>
      </div>
    </Container>
  );
};

export default Goals;
