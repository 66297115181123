import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Slider.scss";
import { getUUID } from "../../utility";

const Slider = ({ items, showArrows = false }) => {
  return (
    <div className="slider">
      <Carousel showArrows={showArrows} showStatus={false}>
        {items.map((item) => (
          <div key={getUUID()} className="item">
            {item}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
