import "./Layout.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = (props) => {
  const header = props.header !== undefined ? props.header : true;
  const footer = props.footer !== undefined ? props.footer : true;

  return (
    <>
      {header && <Header menuSelect={props.menuSelect} />}
      <div id="content-container">{props.children}</div>
      {footer && <Footer />}
    </>
  );
};

export default Layout;
