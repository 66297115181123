import styled from 'styled-components';

export const StyledAccordion = styled.div`
  margin-bottom: 15px;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme?.components?.accordion?.flex};
`;
