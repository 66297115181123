import { FunctionComponent } from 'react';

import Layout from '../Layout/Layout';
import './AboutUs.scss';
import Intro from './Intro/Intro';
import Main from './Main/Main';
// import Team from "./Team/Team";

const AboutUs: FunctionComponent = () => {
  return (
    <div id="aboutus">
      <Layout menuSelect="about-us">
        <Intro />
        <Main />
        {/* <Team /> */}
        <div className="line one"></div>
      </Layout>
    </div>
  );
};

export default AboutUs;
