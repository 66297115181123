import { FunctionComponent } from 'react';

import './Intro.scss';

import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

import buergerbeteiligungIcon from '../../../assets/image/references/buergerbeteiligung.svg';
import bulletpointIcon from '../../../assets/image/references/bulletpointIcon.png';
import Slider from '../../Slider/Slider';

const Intro: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  const sliderItems = [
    translation['references.slider.item.1'],
    translation['references.slider.item.2'],
    translation['references.slider.item.3']
  ].map(text => <div className="styled-slider-item">{text}</div>);

  return (
    <div id="references-intro">
      <div className="absolut-wrapper">
        <div className="headline">{translation['references.intro.headline']}</div>
        <Container>
          <div className="buttetpoint-wrapper">
            <div className="list">
              <div className="item">
                <img src={bulletpointIcon} alt="bulletpoint" />
                {translation['references.bulletpoints.1']}
              </div>
              <div className="item">
                <img src={bulletpointIcon} alt="bulletpoint" />
                {translation['references.bulletpoints.2']}
              </div>
              <div className="item">
                <img src={bulletpointIcon} alt="bulletpoint" />
                {translation['references.bulletpoints.3']}
              </div>
            </div>
            <div className="hightlight">
              <img src={buergerbeteiligungIcon} alt="Bürgerbeteiligung" />
              {translation['references.bulletpoints.highlight']}
            </div>
          </div>
          <div className="slider-wrapper">
            <Slider items={sliderItems} />
          </div>
        </Container>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Intro;
