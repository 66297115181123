import { FunctionComponent } from 'react';

import './Solutions.scss';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';
import Process from './Process/Process';
import HomeProcess from '../Home/Process/Process';

const Solutions: FunctionComponent = () => {
  return (
    <div id="solutions">
      <Layout menuSelect={'solutions'}>
        <Intro />
        <Process />
        <HomeProcess />
        <div className="line one"></div>
      </Layout>
    </div>
  );
};

export default Solutions;
