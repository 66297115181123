import './Intro.scss';
import { useSelector } from 'react-redux';

const Intro = () => {
  const translation = useSelector(state => state.translation);

  return (
    <div id="solutions-photovoltaic">
      <div className="text-wrapper">
        <div className="center-wrapper">
          <div className="text">
            {translation['photovoltaic.intro.headline.1']}
            <br />
            {translation['photovoltaic.intro.headline.2']}
            <br />
            {translation['photovoltaic.intro.headline.3']}
          </div>
        </div>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Intro;
