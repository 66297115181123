import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import "./Construction.scss";
import blocksImage from "../../../assets/image/photovoltaic/blocks.png";
import gridImage from "../../../assets/image/photovoltaic/grid.png";
import fenceImage from "../../../assets/image/photovoltaic/fence.png";

const Construction = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <div id="photovoltaic-construction">
      <Container>
        <div className="subheadline">
          {translation["photovoltaic.construction.subheadline"]}
        </div>
        <div className="headline">
          {translation["photovoltaic.construction.headline"]}
        </div>
        <div className="items">
          <div className="item">
            <div className="image">
              <img src={blocksImage} alt="blocks" />
            </div>
            <div className="headline">
              {translation["photovoltaic.construction.item.1.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.construction.item.1.text.1"]}
              <br />
              {translation["photovoltaic.construction.item.1.text.2"]}
              <br />
              {translation["photovoltaic.construction.item.1.text.3"]}
              <br />
              {translation["photovoltaic.construction.item.1.text.4"]}
              <br />
              {translation["photovoltaic.construction.item.1.text.5"]}
              <br />
              {translation["photovoltaic.construction.item.1.text.6"]}
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={gridImage} alt="grid" />
            </div>
            <div className="headline">
              {translation["photovoltaic.construction.item.2.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.construction.item.2.text.1"]}
              <br />
              {translation["photovoltaic.construction.item.2.text.2"]}
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={fenceImage} alt="fence" />
            </div>
            <div className="headline">
              {translation["photovoltaic.construction.item.3.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.construction.item.3.text.1"]}
              <br />
              {translation["photovoltaic.construction.item.3.text.2"]}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Construction;
