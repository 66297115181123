import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import "./Numberation.scss";
import image01 from "../../../assets/image/photovoltaic/01.png";
import image02 from "../../../assets/image/photovoltaic/02.png";
import image03 from "../../../assets/image/photovoltaic/03.png";
import image04 from "../../../assets/image/photovoltaic/04.png";
import image05 from "../../../assets/image/photovoltaic/05.png";

const Numberation = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <div id="photovoltaic-numberation">
      <Container>
        <div className="subheadline">
          {translation["photovoltaic.numberation.subheadline"]}
        </div>
        <div className="headline">
          {translation["photovoltaic.numberation.headline"]}
        </div>
        <div className="item">
          <div className="image">
            <img src={image01} alt="image01" />
          </div>
          <div className="text">
            {translation["photovoltaic.numberation.item.1.1"]}
            <br />
            {translation["photovoltaic.numberation.item.1.2"]}
          </div>
        </div>
        <div className="item">
          <div className="image">
            <img src={image02} alt="image02" />
          </div>
          <div className="text">
            {translation["photovoltaic.numberation.item.2.1"]}
            <br />
            {translation["photovoltaic.numberation.item.2.2"]}
            <br />
            {translation["photovoltaic.numberation.item.2.3"]}
          </div>
        </div>
        <div className="item">
          <div className="image">
            <img src={image03} alt="image03" />
          </div>
          <div className="text">
            {translation["photovoltaic.numberation.item.3.1"]}
            <br />
            {translation["photovoltaic.numberation.item.3.2"]}
          </div>
        </div>
        <div className="item">
          <div className="image">
            <img src={image04} alt="image04" />
          </div>
          <div className="text">
            {translation["photovoltaic.numberation.item.4.1"]}
            <br />
            {translation["photovoltaic.numberation.item.4.2"]}
            <br />
            {translation["photovoltaic.numberation.item.4.3"]}
          </div>
        </div>
        <div className="item">
          <div className="image">
            <img src={image05} alt="image05" />
          </div>
          <div className="text">
            {translation["photovoltaic.numberation.item.5.1"]}
            <br />
            {translation["photovoltaic.numberation.item.5.2"]}
            <br />
            {translation["photovoltaic.numberation.item.5.3"]}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Numberation;
