import { FunctionComponent } from 'react';

import './Home.scss';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';
import Map from './Map/Map';
import Percent from './Percent/Percent';
import ThreeText from './ThreeText/ThreeText';
import Dome from '../Solutions/Dome/Dome';

const Home: FunctionComponent = () => {
  return (
    <div id="home">
      <Layout menuSelect={'home'}>
        <Intro />
        <ThreeText />
        <Dome />
        <Map />
        <Percent />
        <div className="line one"></div>
        <div className="line two"></div>
      </Layout>
    </div>
  );
};

export default Home;
