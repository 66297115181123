import { FunctionComponent } from 'react';

import './Overview.scss';

import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

import turbineHumanImage from '../../../assets/image/references/grafik01.svg';
import Section from './Section/Section';

const Overview: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="references-overview">
      <Container>
        <div className="headline">{translation['references.overview.headline']}</div>
        <div className="wrapper">
          <img src={turbineHumanImage} alt="Turbine mit Mensch" />
        </div>
        <div className="section-wrapper">
          <Section index={1} bulletPointAmount={4} sliderItemAmount={4} />
          <Section index={2} bulletPointAmount={4} sliderItemAmount={4} />
        </div>
      </Container>
    </div>
  );
};

export default Overview;
