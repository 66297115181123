import { FunctionComponent } from 'react';

import './References.scss';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';
import Overview from './Overview/Overview';

const References: FunctionComponent = () => {
  return (
    <div id="references">
      <Layout menuSelect={'references'}>
        <Intro />
        <Overview />
        <div className="line one"></div>
      </Layout>
    </div>
  );
};

export default References;
