import React from "react";

import ReactDOM from "react-dom";

import "./global.scss";
import { Provider } from "react-redux";
import { createStore } from "redux";

import App from "./App";
import reducer from "./reducer/reducer";
// import TagManager from "react-gtm-module";
// const tagManagerArgs = {
//   gtmId: "",
// };
// TagManager.initialize(tagManagerArgs);

const store = createStore(reducer);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
