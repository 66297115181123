import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Participation from "./components/Participation/Participation";
import Solutions from "./components/Solutions/Solutions";
import Gallery from "./components/Gallery/Gallery";
import References from "./components/References/References";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import DataProtection from "./components/DataProtection/DataProtection";
import Imprint from "./components/Imprint/Imprint";
import Photovoltaic from "./components/Photovoltaic/Photovoltaic";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* LANDINGPAGE */}
          <Route exact={true} path="/">
            <Home />
          </Route>
          {/* PHOTOVOLTAIC */}
          <Route exact={true} path="/photovoltaic">
            <Photovoltaic />
          </Route>
          {/* SOLUTIONS */}
          <Route exact={true} path="/participations">
            <Participation />
          </Route>
          {/* SOLUTIONS */}
          <Route exact={true} path="/solutions">
            <Solutions />
          </Route>
          {/* SOLUTIONS */}
          <Route exact={true} path="/gallery">
            <Gallery />
          </Route>
          {/* REFERENCES */}
          <Route exact={true} path="/references">
            <References />
          </Route>
          {/* ABOUTUS */}
          <Route exact={true} path="/about-us">
            <AboutUs />
          </Route>
          {/* CONTACT */}
          <Route exact={true} path="/contact">
            <Contact />
          </Route>
          {/* IMPRINT */}
          <Route exact={true} path="/imprint">
            <Imprint />
          </Route>
          {/* DATA PROTECTION */}
          <Route exact={true} path="/data-protection">
            <DataProtection />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
