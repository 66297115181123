import Layout from '../Layout/Layout';
import './Gallery.scss';
import Intro from './Intro/Intro';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/esm/Container';
import Collection from './Collection/Collection';
import { getUUID } from '../../utility';

import Bollenhagenproject2DJI_0435 from '../../assets/image/gallery/Bollenhagen/project2-DJI_0435.jpg';
import Bollenhagenproject2DJI_0439 from '../../assets/image/gallery/Bollenhagen/project2-DJI_0439.jpg';
import Bollenhagenproject2DJI_0444 from '../../assets/image/gallery/Bollenhagen/project2-DJI_0444.jpg';
import Bollenhagenproject2DJI_0449 from '../../assets/image/gallery/Bollenhagen/project2-DJI_0449.jpg';
import Bollenhagenproject2DJI_0467 from '../../assets/image/gallery/Bollenhagen/project2-DJI_0467.jpg';

import kroegershammwindparkbockhorn_DJI_0277 from '../../assets/image/gallery/Kroegershamm/windpark--bockhorn_DJI_0277.jpg';
import kroegershammwindparkbockhorn_DJI_0281 from '../../assets/image/gallery/Kroegershamm/windpark--bockhorn_DJI_0281.jpg';
import kroegershammwindparkbockhorn_DJI_0299 from '../../assets/image/gallery/Kroegershamm/windpark--bockhorn_DJI_0299.jpg';
import kroegershammwindparkbockhorn_DJI_0354 from '../../assets/image/gallery/Kroegershamm/windpark--bockhorn_DJI_0354.jpg';
import kroegershammwindparkbockhorn_DJI_0416 from '../../assets/image/gallery/Kroegershamm/windpark--bockhorn_DJI_0416.jpg';

import neuhWerdproject1DJI_0699 from '../../assets/image/gallery/NeuhWerd/project1-DJI_0699.jpg';
import neuhWerdproject1DJI_0756 from '../../assets/image/gallery/NeuhWerd/project1-DJI_0756.jpg';
import neuhWerdproject1DJI_0851 from '../../assets/image/gallery/NeuhWerd/project1-DJI_0851.jpg';
import neuhWerdproject1DJI_08722 from '../../assets/image/gallery/NeuhWerd/project1-DJI_0872-2.jpg';
import neuhWerdproject1DJI_0878 from '../../assets/image/gallery/NeuhWerd/project1-DJI_0878.jpg';

const Gallery = () => {
  const translation = useSelector(state => state.translation);
  const collections = [
    {
      name: translation['gallery.collection.new'],
      googleMapsData: {
        data: [
          {
            state: 'Niedersachsen',
            purpose: 'Windkraft',
            location: '26434 Wangerland',
            company: 'Pievens-Süderhausen',
            lat: '53.6700',
            lng: '7.9301'
          },
          {
            state: 'Niedersachsen',
            purpose: 'Windkraft',
            location: '29568 Wrestedt',
            company: 'Könau-Ostedt-Suhledorf',
            lat: '52.8900',
            lng: '10.6517'
          },
          {
            state: 'Niedersachsen',
            purpose: 'Windkraft - Repowering',
            location: '26340 Zetel',
            company: 'Bullenmeer',
            lat: '53.3964',
            lng: '7.9522'
          },
          {
            state: 'Brandenburg',
            purpose: 'Windkraft - Repowering',
            location: '16321 Bernau',
            company: 'Birkholz Repowering',
            lat: '52.6875',
            lng: '13.5673'
          },
          {
            state: 'Niedersachsen',
            purpose: 'Windkraft',
            location: '26169 Friesoythe',
            company: 'Thüle',
            lat: '53.0233',
            lng: '7.8585'
          }
        ],
        lat: '53.0793',
        lng: '8.8017',
        zoom: 7
      }
    },
    {
      name: translation['gallery.collection.bollenhagen'],
      images: [
        Bollenhagenproject2DJI_0435,
        Bollenhagenproject2DJI_0439,
        Bollenhagenproject2DJI_0444,
        Bollenhagenproject2DJI_0449,
        Bollenhagenproject2DJI_0467
      ],
      googleMapsData: {
        state: 'Niedersachsen',
        manufacturer: 'Siemens',
        type: '2 x SWT 3.0 113',
        totalPower: '6 MW',
        finalization: 'Mrz 17',
        name: 'Windpark Bollenhagen',
        lat: '53.339885',
        lng: '8.266128'
      }
    },
    {
      name: translation['gallery.collection.kroegershamm'],
      images: [
        kroegershammwindparkbockhorn_DJI_0277,
        kroegershammwindparkbockhorn_DJI_0281,
        kroegershammwindparkbockhorn_DJI_0299,
        kroegershammwindparkbockhorn_DJI_0354,
        kroegershammwindparkbockhorn_DJI_0416
      ],
      googleMapsData: {
        data: [
          {
            manufacturer: 'SENVION',
            state: 'Niedersachsen',
            type: '1 x MM 92',
            totalPower: '2,05 MW',
            finalization: 'Feb 14',
            name: 'Windpark Krögershamm'
          },
          {
            state: 'Niedersachsen',
            manufacturer: 'SENVION',
            type: '2 x 3.2 M 114',
            totalPower: '6,40 MW',
            finalization: 'Feb 14',
            name: 'Windpark Krögershamm'
          },
          {
            state: 'Niedersachsen',
            manufacturer: 'SENVION',
            type: '1 x 3.4 M 114',
            totalPower: '3,4 MW',
            finalization: 'Nov 16',
            name: 'Windpark Krögershamm'
          }
        ],
        lat: '53.420613',
        lng: '8.055231'
      }
    },
    {
      name: translation['gallery.collection.weuh-werd'],
      images: [
        neuhWerdproject1DJI_0699,
        neuhWerdproject1DJI_0756,
        neuhWerdproject1DJI_0851,
        neuhWerdproject1DJI_08722,
        neuhWerdproject1DJI_0878
      ],
      googleMapsData: {
        state: 'Niedersachsen',
        manufacturer: 'ENERCON',
        type: '4 x E-126',
        totalPower: '30,0 MW',
        finalization: 'Jan 14',
        name: 'Windpark NEUH-WERD',
        lat: '53.685246',
        lng: '7.73109'
      }
    },
    {
      name: 'Windpark Bassens II',
      googleMapsData: {
        state: 'Niedersachsen',
        manufacturer: 'ENERCON',
        type: '3 x E-82',
        totalPower: '6,0 MW',
        finalization: 'Nov 06',
        name: 'Windpark Bassens II',
        lat: '53.691698',
        lng: '7.914775'
      }
    },
    {
      name: 'Windpark Bassens I',
      googleMapsData: {
        state: 'Niedersachsen',
        manufacturer: 'Siemens',
        type: '10 x SWT 3.0-113',
        totalPower: '30,0 MW',
        finalization: 'Feb 14',
        name: 'Windpark Bassens I',
        lat: '53.695447',
        lng: '7.931099'
      }
    },
    {
      name: 'Windpark Bramsche - Wittfeld',
      googleMapsData: {
        state: 'Niedersachsen',
        manufacturer: 'SENVION',
        type: '6 x 3.0 M 122',
        totalPower: '18 MW',
        finalization: 'Dez 16',
        name: 'Windpark Bramsche - Wittfeld',
        lat: '52.431874',
        lng: '8.067033'
      }
    },
    {
      name: 'Windpark Bramsche - Ahrensfeld',
      googleMapsData: {
        data: [
          {
            state: 'Niedersachsen',
            manufacturer: 'SENVION',
            type: '1 x 3.0 M 122',
            totalPower: '3,0 MW',
            finalization: 'Mai 17',
            name: 'Windpark Bramsche - Ahrensfeld'
          },
          {
            state: 'Niedersachsen',
            manufacturer: 'SENVION',
            type: '1 x 3.2 M 122',
            totalPower: '3,2 MW',
            finalization: 'Mai 17',
            name: 'Windpark Bramsche - Ahrensfeld'
          },
          {
            state: 'Niedersachsen',
            manufacturer: 'SENVION',
            type: '5 x 3.4 M 122 NES',
            totalPower: '17,2 MW',
            finalization: 'Mai 17',
            name: 'Windpark Bramsche - Ahrensfeld'
          }
        ],
        lat: '52.429655',
        lng: '8.025884'
      }
    },
    {
      name: 'Windpark Ovelgönne',
      googleMapsData: {
        state: 'Niedersachsen',
        manufacturer: 'Enercon',
        type: '12 x E126',
        totalPower: '50,4 MW',
        finalization: 'Jun 18',
        name: 'Windpark Ovelgönne',
        lat: '53.350924',
        lng: '8.405536'
      }
    }
  ];

  return (
    <div id="gallery">
      <Layout menuSelect={'gallery'}>
        <Intro />
        <Container>
          {collections.map((c, i) => (
            <Collection
              name={c.name}
              images={c.images}
              videos={c.videos}
              googleMapsData={c.googleMapsData}
              index={i}
              key={getUUID()}
            />
          ))}
        </Container>
        <div className="line one"></div>
      </Layout>
    </div>
  );
};

export default Gallery;
