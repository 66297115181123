import "./Overview.scss";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import panelImg from "../../../assets/image/photovoltaic/pannel.png";

const Overview = () => {
  const translation = useSelector((state) => state.translation);

  return (
    <Container>
      <div id="photovoltaic-overview">
        <div className="image">
          <img src={panelImg} alt="Pannel" />
        </div>
        <div className="content">
          <div className="subheadline">
            {translation["photovoltaic.overview.subheadline"]}
          </div>
          <div className="headline">
            {translation["photovoltaic.overview.headline"]}
          </div>
          <div className="text">
            <div className="headline">
              {translation["photovoltaic.overview.text.1.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.overview.text.1.text"]}
            </div>
          </div>
          <div className="text">
            <div className="headline">
              {translation["photovoltaic.overview.text.2.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.overview.text.2.text"]}
            </div>
          </div>
          <div className="text">
            <div className="headline">
              {translation["photovoltaic.overview.text.3.headline"]}
            </div>
            <div className="text">
              {translation["photovoltaic.overview.text.3.text"]}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Overview;
