import { FunctionComponent } from 'react';

import './Contact.scss';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';
import Plugin from './Plugin/Plugin';
// import Map from "./Map/Map";

const Contact: FunctionComponent = () => {
  return (
    <div id="contact">
      <Layout menuSelect={'contact'}>
        <Intro />
        <Plugin />
        {/* <Map /> */}
        <div className="line one"></div>
        {/* <div className="line two"></div> */}
        {/* add Oncehub to contact */}
        {/* add map to gallery */}
      </Layout>
    </div>
  );
};

export default Contact;
