import styled from 'styled-components';

export const StyledItem = styled.div`
  width: 100%;
  border: thin solid #efb891;
  margin-bottom: 5px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  background-color: white;
  color: #efb891;
  padding: 5px 10px;
  position: relative;
  border-radius: 2px;
  cursor: pointer;
`;

export const Title = styled.div``;

export const OpenIcon = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  right: 10px;
  display: flex;
  align-items: center;
  color: #4b4b4d;
`;

export const Body = styled.div`
  padding: 5px 10px;
  background-color: white;
  border-radius: 3px;
`;
