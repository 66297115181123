import { FunctionComponent } from 'react';

import './Collection.scss';

import { getUUID } from '../../../utility';
import Slider from '../../Slider/Slider';
import GoogleMap from './GoogleMap/GoogleMap';

type Props = {
  name: string;
  images: string[];
  videos: string[];
  googleMapsData: any;
  index: string;
};

const Collection: FunctionComponent<Props> = ({ name, images, videos, googleMapsData, index }) => {
  const getSliderImages = () => {
    return images.map(i => (
      <div className="slider-image" key={getUUID()}>
        <img src={i} alt={name} />
      </div>
    ));
  };

  return (
    <div className="gallery-collection">
      <div className="name">{name}</div>
      <div className="data-split">
        {googleMapsData && (
          <div className={`google-maps-embed ${images ? 'split' : ''}`}>
            <GoogleMap data={googleMapsData} index={index} />
          </div>
        )}
        {images && (
          <div className="images">
            <Slider showArrows={true} items={getSliderImages()} />
          </div>
        )}
        {videos && (
          <div className="videos">
            {videos.map(v => (
              <div className="video" key={getUUID()}>
                <video controls>
                  <source src={v} type="video/mp4" />
                </video>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Collection;
