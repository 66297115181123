import Container from "react-bootstrap/Container";
import "./Footer.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import facebookIcon from "../../assets/image/footer/facebook.svg";
// import instagramIcon from "../../assets/image/footer/instagram.svg";
// import linkedinIcon from "../../assets/image/footer/linkedin.svg";
// import mailIcon from "../../assets/image/footer/mail.svg";
// import twitterIcon from "../../assets/image/footer/twitter.svg";

const Footer = () => {
  const history = useHistory();
  // @ts-ignore
  const translation = useSelector((state) => state.translation);

  // @ts-ignore
  const linkClicked = (e) => {
    let link = null;
    if (e.target.attributes.href) {
      link = e.target.attributes.href.value;
    } else if (e.target.parentElement.attributes.href) {
      link = e.target.parentElement.attributes.href.value;
    }
    if (link && link.startsWith("/")) {
      e.preventDefault();
      history.push(link);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div id="footer">
      <div className="info">
        <Container>
          <div className="block social">
            {/* <div className="icon"><a href="/" onClick={linkClicked}><img src={mailIcon} alt="E-Mail" /></a></div>
            <div className="icon"><a href="/" onClick={linkClicked}><img src={twitterIcon} alt="Twitter" /></a></div>
            <div className="icon"><a href="/" onClick={linkClicked}><img src={linkedinIcon} alt="LinkedIn" /></a></div>
            <div className="icon"><a href="/" onClick={linkClicked}><img src={facebookIcon} alt="Facebook" /></a></div>
            <div className="icon"><a href="/" onClick={linkClicked}><img src={instagramIcon} alt="Instagram" /></a></div> */}
          </div>
          <div className="block legal">
            <div>
              <a href="/imprint" onClick={linkClicked}>
                {translation["footer.imprint"]}
              </a>{" "}
              /{" "}
              <a href="/data-protection" onClick={linkClicked}>
                {translation["footer.data-protection"]}
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="copyright">
        <Container>
          <div>JADE GROUP {new Date().getFullYear()}</div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
