import { useState } from 'react';

import './ParkModal.scss';

import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { getUUID } from '../../../utility';
import Accordion from './Accordion/Accordion';

const ParkModal = ({ image, name, children, downloads }) => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);
  const [show, setShow] = useState(false);
  const uuid = getUUID();

  const getFileName = link => {
    const split = link.split('/');
    return decodeURIComponent(split[split.length - 1]).replaceAll('+', ' ');
  };

  const buildItem = download => {
    return {
      title:
        downloads.length > 1
          ? `${translation['participation.park.download-cta']} ${download.name}`
          : translation['participation.park.download-cta'],
      content: (
        <div>
          {download.items.map(link => (
            <div key={getUUID()}>
              <a target="_blank" rel={'noreferrer'} className="download-link" href={link}>
                {getFileName(link)}
              </a>
            </div>
          ))}
        </div>
      )
    };
  };

  return (
    <div className="park-modal-wrapper">
      <div className="cta" onClick={() => setShow(true)}>
        <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
        <div className="name">{name}</div>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby={uuid}
        className="park-modal"
      >
        <Modal.Header>
          <Modal.Title id={uuid}>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(downloads) && <Accordion items={downloads.map(buildItem)} />}
          {children}
          <div className="close-cta-wrapper">
            <div className="close" onClick={() => setShow(false)}>
              {translation['participation.park.close-cta']}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ParkModal;
