import { useSelector } from 'react-redux';
import './Intro.scss';

const Intro = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="contact-intro">
      <div className="text-wrapper">
        <div className="center-wrapper">
          <div className="text">{translation['contact.intro.headline']}</div>
        </div>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Intro;
