import { FunctionComponent } from 'react';

import './Dome.scss';

import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

import domeImage from '../../../assets/image/solutions/dome.png';

const Map: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="solutions-dome">
      <Container>
        <div className="wrapper">
          <img src={domeImage} alt="Eigentümerwindpark" />
        </div>
        <div className="headline">{translation['solutions.dome.headline']}</div>
        <div className="text-split">
          <div className="text-section">
            <div className="text">{translation['solutions.dome.text.1']}</div>
            <div className="text sub">{translation['solutions.dome.text.2']}</div>
            <div className="text">{translation['solutions.dome.text.3']}</div>
            <div className="text">{translation['solutions.dome.text.4']}</div>
          </div>
          <div className="text-section">
            <div className="text">{translation['solutions.dome.text.4.1']}</div>
            <div className="text bold">{translation['solutions.dome.text.5']}</div>
            <div className="text">{translation['solutions.dome.text.6']}</div>
            <div className="text bold">{translation['solutions.dome.text.7']}</div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Map;
