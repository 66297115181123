import { FunctionComponent } from 'react';

import './Process.scss';

import { useSelector } from 'react-redux';

import { getUUID } from '../../../utility';

const Process: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="home-process">
      <div className="headline">{translation['home.process.headline']}</div>
      <div className="bulletpoints">
        {[...new Array(6)].map((empty, i) => {
          return (
            <div className={`point${(i + 1) % 2 === 0 ? ' equal' : ''}`} key={getUUID()}>
              <div className="index">{i + 1}</div>
              <div className="content">
                <div className="subheadline">
                  {translation[`home.process.bulletpoint.${i + 1}.headline`]}
                </div>
                <div className="text">{translation[`home.process.bulletpoint.${i + 1}.text`]}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Process;
