import { FunctionComponent, useEffect } from 'react';

import './Header.scss';

import { useHistory } from 'react-router-dom';

import headerLogo from '../../assets/image/logo.png';
import Navigation from './Navigation/Navigation';

type Props = {
  menuSelect?: string;
};

const Header: FunctionComponent<Props> = ({ menuSelect }) => {
  const history = useHistory();

  const handleScroll = () => {
    // @ts-ignore
    document.querySelector('#header').className =
      window.scrollY > (window.innerHeight / 100) * 80 ? 'user-scrolled' : '';
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  // @ts-ignore
  const goHome = e => {
    e.preventDefault();
    window.scrollTo(0, 0);
    history.push('/');
  };

  return (
    <div id="header">
      <div className="logo" onClick={goHome}>
        <img src={headerLogo} alt="Jade" />
      </div>
      <div className="navigation-wrapper">
        <Navigation menuSelect={menuSelect} />
      </div>
    </div>
  );
};

export default Header;
