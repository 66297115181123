import { FunctionComponent } from 'react';

import './Percent.scss';

import { useSelector } from 'react-redux';

import percentImage from '../../../assets/image/start/percent_bigger.png';

const Percent: FunctionComponent = () => {
  // @ts-ignore
  const translation = useSelector(state => state.translation);

  return (
    <div id="home-percent">
      <div className="headline">{translation['home.percent.headline']}</div>
      <div className="wrapper">
        <img src={percentImage} alt="Deutschland-Karte" />
      </div>
      <div className="text-wrapper">
        <div className="text">
          {translation['home.percent.text.1']}
          <br />
          {translation['home.percent.text.2']}
          <br />
          {translation['home.percent.text.3']}
        </div>
      </div>
    </div>
  );
};

export default Percent;
