import { StyledItem, Header, Body, OpenIcon, Title } from './Item.Styled'
import React from 'react'

const Item = ({ title, content, open, toggle, id }) => {
  return (
    <StyledItem id={id}>
      <Header onClick={toggle}>
        <Title>{title}</Title>
        <OpenIcon>{open ? '▼' : '▲'}</OpenIcon>
      </Header>
      {open && <Body>{content}</Body>}
    </StyledItem>
  )
}

export default Item
