import * as types from "../type/types";
import * as translation from './translation'
import de, { langKey } from './translationFiles/de'
import * as user from "./user";


const defaultState = {
  translation: de,
  token: null,
  user: null,
  currentLang: langKey
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_USER_TOKEN:
      return user.setToken(state, action);
    case types.SET_USER:
      return user.set(state, action);
    case types.SET_TRANSLATION:
      return translation.set(state, action);
    default:
      return state;
  }
}

export default reducer;
